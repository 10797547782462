<template>
    <div>
      <!-- 프린트 페이지 , 데이터 바인딩 수정 -->
            <div id="printArea" >
                <div class="book">
                        <div class="page">
                            <div id="canvasWrap"> </div>
                            <!-- <QrcodeVue id='canvas' v-model="QRvalue">
                            </QrcodeVue> -->
                            <!-- <QrcodeVue v-if="printdata[0]" style='width:50px;position:relative;left:580px;top:150px' id='canvas' :value='printdata[0].ORDER_NUM' size='50' level='H'>
                            </QrcodeVue> -->
                            <div class="subpage">
                                <h2>발주서</h2>
                                
                                <table class="infotable">
                                    <colgroup>
                                        <col width="40%">
                                        <col width="3%">
                                        <col width="12%">
                                        <col width="19%">
                                        <col width="10%">
                                        <col width="16%">
                                    </colgroup>
                                    <tr>



                                        <td rowspan="4">
                                            <div class="td1name">
                                                
                                                <span>{{printdata[0] ? $거래처코드(printdata[0].CUSTOM_CODE) : ""}} 귀하</span>
                                            </div>
                                            <div class="td1date">
                                                <span >발주일자 :
                                                {{printdata[0] ? printdata[0].ORDER_DATE.substr(0,4) : ""}}년
                                                {{printdata[0] ? printdata[0].ORDER_DATE.substr(5,2) : ""}}월
                                                {{printdata[0] ? printdata[0].ORDER_DATE.substr(8,2) : ""}}일
                                            </span>
                                                
                                            </div>
                                            <p>납기일 내에 인도해 주시길 바랍니다.</p>
                                        </td>
                                        <td rowspan="4" class="td2">발주자</td>
                                        <td>등록번호</td>
                                        <td colspan="3" >
                                           {{printdata[0] ? printdata[0].ORDER_NUM : ""}}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="lgletter">상 호</td>
                                        <td>
                                            유원코스메틱
                                        </td>
                                        <td class="lgletter">성 명</td>
                                        <td>
                                           엄은경
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="lgletter">주 소</td>
                                        <td colspan="3">
                                            서울특별시 마포구 독막로 306(염리동) 4층
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>전화번호</td>
                                        <td colspan="3">
                                            070-7707-3236
                                        </td>
                                       
                                    </tr>
                                </table>
                                <table class="datatable">
                                    <colgroup>
                                        <col width="4%">
                                        <col width="20%">
                                        <col width="6%">
                                        <col width="12%">
                                        <col width="12%">
                                        <col width="12%">
                                        <col width="12%">
                                        <col width="20%">
                                        <col width="12%">
                                    </colgroup>
                                    <tr>
                                        <th>순번</th>
                                        <th>품목명/규격</th>
                                        <th>단위</th>
                                        <th>수량</th>
                                        <th>단가</th>
                                        <th>공급가액</th>
                                        <th>세액</th>
                                        <th>납기일</th>
                                        <th>비고</th>
                                    </tr>
                                   
                                    <tr v-for="(item,i) in printdata" :key="i">
                                        <td>{{i+1}}</td>
                                        <td>{{$품목코드(item.ITEM_CODE,"ITEM_NAME")}}</td>
                                        <td>{{item.ORDER_UNIT}}</td>
                                        <td class="txtright">{{$num(item.ORDER_Q)}}</td>
                                        <td class="txtright">{{$num(item.ORDER_UNIT_P)}}</td>
                                        <td class="txtright">{{$num(item.ORDER_P)}}</td>
                                        <td class="txtright">{{$num(item.ORDER_P * 10/100)}}</td>
                                        <td>{{item.DVRY_DATE}}</td>
                                        <td>{{item.TEMPN_01}}</td>
                                    </tr>
                                 
                                    <tr style="border-top:1px bold black">
                                        <th colspan="4">합계</th>
                                        <td class="txtright">{{$num(total.단가합)}}</td>
                                        <td class="txtright">{{$num(total.공급가액합)}}</td>
                                        <td class="txtright">{{$num(total.세액합)}}</td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </table>
                                <table class="info2table">
                                    <colgroup>
                                        <col width="18%">
                                        <col width="34%">
                                        <col width="18%">
                                        <col width="34%">
                                    </colgroup>
                                   
                                    <tr>
                                        <th>유효일자</th>
                                        <td></td>
                                        <th>결제조건</th>
                                        <td></td>
                                    </tr>
                                    
                                </table>
                               
                            </div>    
                        </div>
                    </div>
             </div>

    </div>
</template>
<script>
    export default {
        data() {
            return {
                property: 'value',
                

            };
        },
        props: {
            printdata: Array,
            total: Object
        },
        computed: {
        },

        methods: {

        },
        mounted(){
        }

    }
</script>
<style scoped lang="scss">
    @import '@/assets/style/print_balju.scss';
</style>