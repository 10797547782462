import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import {commonData} from './commonData'
import axios from 'axios'
import dayjs from 'dayjs'

import balju from '@/components/프린트/발주서.vue'
Vue.component('balju', balju)


import  mixin  from './mixin'
Vue.mixin(mixin)

//부트스틃(메뉴탭)
import BootstrapVue from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.use(BootstrapVue)
//////////////////////

Vue.prototype.$eventBus=new Vue()
Vue.config.productionTip = false
Vue.prototype.$commonData = commonData
Vue.prototype.$uri = commonData.uri
Vue.prototype.$uri2 = commonData.uri2
Vue.prototype.axios = axios
Vue.prototype.dayjs = dayjs
new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
