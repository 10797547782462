import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

const 레이아웃 = () => {  return import ("./components/layout.vue")}
const 로그인 = () => {  return import ("./components/login.vue")}





//[기준관리]
//기준정보관리
const 거래처등록 = () => {  return import ("./components/기준관리/거래처등록.vue")}
const 품목관리 = () => {  return import ("./components/기준관리/품목등록.vue")}
const 작업장관리 = () => {  return import ("./components/기준관리/작업장등록.vue")}
const BOM역전개 = () => {  return import ("./components/기준관리/BOM역전개.vue")}
const 사용자활동이력 = () => {  return import ("./components/기준관리/사용자활동이력.vue")}
const BOM정전개조회 = () => {  return import ("./components/기준관리/BOM정전개조회.vue")}
const BOM등록 = () => {  return import ("./components/기준관리/BOM등록.vue")}
const 사원정보관리 = () => {  return import ("./components/기준관리/사용자등록.vue")}
const 사원비밀번호변경 = () => {  return import("./components/기준관리/사용자암호변경.vue")}
const infotab = () => {  return import ("./components/기준관리/infotab.vue")}
const 업무코드등록 = () => {  return import ("./components/기준관리/업무코드등록.vue")}
const 프로그램 = () => {  return import ("./components/기준관리/프로그램.vue")}
const 사용자권한 = () => {  return import ("./components/기준관리/사용자권한.vue")}
const 그룹권한 = () => {  return import ("./components/기준관리/그룹권한.vue")}
const 자료형식 = () => {  return import ("./components/기준관리/자료형식.vue")}
const 채번형식등록 = () => {  return import ("./components/기준관리/채번형식등록.vue")}
const 부서관리 = () => {  return import ("./components/기준관리/부서관리.vue")}
const 창고등록 = () => {  return import ("./components/기준관리/창고등록.vue")}

//구매관리
const 발주등록 = () => {  return import ("./components/구매관리/발주등록.vue")}
const 발주확정 = () => {  return import ("./components/구매관리/발주확정.vue")}
const 발주승인 = () => {  return import ("./components/구매관리/발주승인.vue")}
const 발주현황 = () => { return import("./components/구매관리/발주현황.vue") }
const 발주서출력 = () => { return import("./components/구매관리/발주서출력.vue") }
const 미입고현황 = () => { return import("./components/구매관리/미입고현황.vue") }
const 거래처별단가등록 = () => {  return import ("./components/구매관리/거래처별단가등록.vue")}
const 거래처별품목정보등록 = () => {  return import ("./components/구매관리/거래처별품목정보등록.vue")}
const 구매계획등록 = () => {  return import ("./components/구매관리/구매계획등록.vue")}
const 자재소요량계산 = () => {  return import ("./components/구매관리/자재소요량계산.vue")}
const 소요량내역조회 = () => {  return import ("./components/구매관리/소요량내역조회.vue")}
const 소요량확정 = () => {  return import ("./components/구매관리/소요량확정.vue")}
const 소요량조정 = () => {  return import ("./components/구매관리/소요량조정.vue")}
const 지급결의등록 = () => {  return import ("./components/구매관리/지급결의등록.vue")}
const 미지급결의현황 = () => {  return import ("./components/구매관리/미지급결의현황.vue")}
const 매입금집계현황 = () => {  return import ("./components/구매관리/매입금집계현황.vue")}
const 구매업무관리 = () => {  return import ("./components/구매관리/구매업무관리.vue")}


//영업관리

const aa = () => {  return import ("./components/영업관리/ㅇㅇㅇ.vue")}
const 수주등록 = () => {  return import ("./components/영업관리/수주등록.vue")}
const 영업출고등록1 = () => {  return import ("./components/영업관리/영업출고등록.vue")}
const 수주조회 = () => {  return import ("./components/영업관리/수주조회.vue")}
const 출하요청등록 = () => {  return import ("./components/영업관리/출하요청등록.vue")}
const 출하등록 = () => {  return import ("./components/영업관리/출하등록.vue")}
const 출하등록2 = () => { return import("./components/영업관리/출하등록 copy.vue") }
// 새로추가
const 재고현황 = () => { return import("./components/영업관리/재고현황.vue") }
const 납기단축 = () => { return import("./components/영업관리/납기단축.vue") }

//품질관리
const 검사항목등록 = () => {  return import ("./components/품질관리/검사항목등록.vue")}
const 불량원인등록 = () => {  return import ("./components/품질관리/불량원인등록.vue")}
const 불량코드등록 = () => {  return import ("./components/품질관리/불량코드등록.vue")}
const 자재검사요청등록 = () => {  return import ("./components/품질관리/자재검사요청등록.vue")}
const 자재검사등록 = () => { return import("./components/품질관리/자재검사등록.vue") }
const 완제품검사요청등록 = () => { return import("./components/품질관리/완제품검사요청등록.vue") }
const 완제품검사내역등록 = () => { return import("./components/품질관리/완제품검사등록.vue") }
const 완제품검사수정 = () => { return import("./components/품질관리/완제품검사수정.vue") }
const 완제품검사조회 = () => { return import("./components/품질관리/완제품검사조회.vue") }
const 자재검사조회 = () => { return import("./components/품질관리/자재검사조회.vue") }
const 자재검사요청조회 = () => { return import("./components/품질관리/자재검사요청조회.vue") }
const 클레임등록 = () => { return import("./components/품질관리/클레임등록.vue") }
const 클레임전송 = () => { return import("./components/품질관리/클레임전송.vue") }
const 클레임내역조회 = () => { return import("./components/품질관리/클레임내역조회.vue") }

//재고관리
const 현재고현황품목별 = () => { return import("./components/재고관리//현재고현황(품목별).vue") }
const 현재고현황창고별 = () => { return import("./components/재고관리/현재고현황(창고별).vue") }
const 월별수불현황 = () => { return import("./components/재고관리/월별수불현황.vue") }
const 실사선별현황 = () => { return import("./components/재고관리/실사선별현황.vue") }
const 기초재고등록 = () => { return import("./components/재고관리/기초재고등록.vue") }
const 미사용재고조회 = () => { return import("./components/재고관리/미사용재고조회.vue") }
const 불용재고조회 = () => { return import("./components/재고관리/불용재고조회.vue") }
const 수불현황 = () => { return import("./components/재고관리/수불현황.vue") }
const 실사등록 = () => { return import("./components/재고관리/실사등록.vue") }
const 실사선별작업 = () => { return import("./components/재고관리/실사선별작업.vue") }
const 실사조정 = () => { return import("./components/재고관리/실사조정.vue") }
const 월마감작업 = () => { return import("./components/재고관리/월마감작업.vue") }
const 재고이동요청등록 = () => { return import("./components/재고관리/재고이동요청등록.vue") }
const 재고이동요청현황 = () => { return import("./components/재고관리/재고이동요청현황.vue") }
const 재고이동입고등록 = () => { return import("./components/재고관리/재고이동입고등록.vue") }
const 재고이동입고현황 = () => { return import("./components/재고관리/재고이동입고현황.vue") }
const 재고이동출고등록 = () => { return import("./components/재고관리/재고이동출고등록.vue") }
const 재고이동출고현황 = () => { return import("./components/재고관리/재고이동출고현황.vue") }
const 재고평가 = () => { return import("./components/재고관리/재고평가.vue") }




//외주관리
const 외주PL등록 = () => { return import("./components/외주관리/외주PL등록.vue") }
const 외주PL정보조회 = () => { return import("./components/외주관리/외주PL정보조회.vue") }
const 외주발주등록 = () => { return import("./components/외주관리/외주발주등록.vue") }
const 외주검사요청등록 = () => { return import("./components/외주관리/외주검사요청등록.vue") }
const 외주출고등록 = () => { return import("./components/외주관리/외주출고등록.vue") }
const 외주입고등록 = () => { return import("./components/외주관리/외주입고등록.vue") }
const 외주출고조회 = () => { return import("./components/외주관리/외주출고조회.vue") }
const 외주입고조회 = () => { return import("./components/외주관리/외주입고조회.vue") }
const 외주현재고현황 = () => { return import("./components/외주관리/외주현재고현황.vue") }
const 외주실사선별작업 = () => { return import("./components/외주관리/외주실사선별작업.vue") }
const 외주실사등록 = () => { return import("./components/외주관리/외주실사등록.vue") }
const 외주실사조회 = () => { return import("./components/외주관리/외주실사조회.vue") }
const 외주기초재고등록 = () => { return import("./components/외주관리/외주기초재고등록.vue") }
const 외주발주조회 = () => { return import("./components/외주관리/외주발주조회.vue") }
const 외주미출고조회 = () => { return import("./components/외주관리/외주미출고조회.vue") }
const 외주미입고조회 = () => { return import("./components/외주관리/외주미입고조회.vue") }



//수불관리
const 영업출고현황 = () => {return import("./components/수불관리/영업출고현황.vue")}
const 구매입고등록 = () => {return import("./components/수불관리/구매입고등록.vue")}
const 자재출고등록 = () => {return import("./components/수불관리/자재출고등록.vue")}
const 영업입고등록 = () => {return import("./components/수불관리/영업입고등록.vue")}
const 영업출고등록 = () => {return import("./components/수불관리/영업출고등록.vue")}
const 영업출하등록 = () => {return import("./components/수불관리/영업출하등록.vue")}
const 영업반품등록 = () => {return import("./components/수불관리/영업반품등록.vue")}
const 자재입고조회 = () => {return import("./components/수불관리/자재입고조회.vue")}
const 자재출고조회 = () => {return import("./components/수불관리/자재출고조회.vue")}
const 영업입고조회 = () => {return import("./components/수불관리/영업입고조회.vue")}
const 영업출고조회 = () => {return import("./components/수불관리/영업출고조회.vue")}
const 영업반품조회 = () => {return import("./components/수불관리/영업반품조회.vue")}
const 거래명세서인쇄 = () => {return import("./components/수불관리/거래명세서인쇄.vue")}

//생산관리
const 생산계획등록 = () => {return import("./components/생산관리/생산계획등록.vue")}
const 공정등록 = () => {  return import ("./components/생산관리/공정등록.vue")}
const 공정수순등록 = () => {  return import ("./components/생산관리/공정수순등록.vue")}

//모니터링
const 거래처별입고현황 = () => {return import("./components/모니터링/거래처별입고현황.vue")}
const 거래처별출고현황 = () => { return import("./components/모니터링/거래처별출고현황.vue") }


//외주처관리
const 월자재소요계획 = () => { return import("./components/외주처관리/월자재소요계획.vue") }
const 일별검수실적 = () => { return import("./components/외주처관리/일별검수실적.vue") }
const 월별검수실적 = () => { return import("./components/외주처관리/월별검수실적.vue") }
const 주간자재소요계획 = () => { return import("./components/외주처관리/주간자재소요계획.vue") }
const 정상자재발주 = () => { return import("./components/외주처관리/정상자재발주.vue") }
const 추가자재발주 = () => { return import("./components/외주처관리/추가자재발주.vue") }
const ASK발주 = () => { return import("./components/외주처관리/ASK발주.vue") }
const 납품전표등록 = () => { return import("./components/외주처관리/납품전표등록.vue") }
const 일별납품실적 = () => { return import("./components/외주처관리/일별납품실적.vue") }
const 월별납품실적 = () => { return import("./components/외주처관리/월별납품실적.vue") }
const 매출자료출력 = () => { return import("./components/외주처관리/매출자료출력.vue") }

const router = new VueRouter({
    mode: "history",
    routes: [
        {path:"/login",component:로그인},
        {path:"/",component:레이아웃,meta:{requiresAuth:true},
            children:[
      

            //기준관리            
            {path:"aa",component:aa},
            {path:"BS400E_DEA",component:거래처등록},
            {path:"BS450E",component:품목관리},
            {path:"BS510E",component:작업장관리},
            {path:"BS010E_DEA",component:사원정보관리},    
            {path:"BS010E_DEB",component:사원비밀번호변경},    
            {path:"BS620E",component:BOM역전개},
            {path:"BS040E",component:사용자활동이력},
            {path:"BS610E",component:BOM정전개조회},
            {path:"BS600E",component:BOM등록},
            {path:"BS000E",component:업무코드등록},
            {path:"BS020E",component:프로그램},
            {path:"BS030E",component:사용자권한},
            {path:"BS035E",component:그룹권한},
            {path:"BS050E",component:자료형식},
            {path:"BS150E",component:채번형식등록},
            {path:"BS200E",component:부서관리},
            {path:"BS460E_GUR",component:창고등록},
            
  
            //구매관리
     
            {path:"MM001E",component:거래처별단가등록},
            {path:"MM004Q",component:구매계획등록},
            {path:"MM002E", component:거래처별품목정보등록},
            {path: "MM005", component: 자재소요량계산},
            {path: "MM006", component: 소요량내역조회},
            {path: "MM007", component: 소요량확정},
            {path: "MM008", component: 소요량조정},
            {path:"MM205E",component:발주등록},
            {path:"MM204E",component:발주확정},
            {path:"MM206E",component:발주승인},
            {path: "MM241E", component: 발주현황},
            {path: "MM207E", component: 발주서출력},
            {path: "MM600E", component: 미입고현황},
            {path: "MM951E_DEA", component: 지급결의등록},
            {path: "MM952EDEA2", component: 미지급결의현황},
            {path: "MM952E_DEA", component: 매입금집계현황},
            {path:"MM003Q",component:구매업무관리},


           
            //영업관리
            {path:"SD401E_AIR",component:수주등록},
            {path:"SD492Q_DDS",component:영업출고등록1},
            {path:"SD493Q_DDS",component:수주조회},
            { path: "SD401E_AIR", component: 수주등록 },
            { path: "SD494Q_DDS", component: 출하요청등록 },
            { path: "SD495Q_DDS", component: 출하등록 },
                { path: "SD495Q_DDS2", component: 출하등록2 },
                // 새로 추가
                { path: "SD497Q_DDS", component: 재고현황 },
                { path: "SD496Q_DDS", component: 납기단축 },
            
        

         
            //품질관리
            {path:"QM001E", component: 검사항목등록},
            {path: "QM003E", component: 불량원인등록 },
            {path:"QM002E", component: 불량코드등록},
            {path:"QM301E_GUR", component: 자재검사요청등록},
            {path: "QM310E_GUR", component: 자재검사등록},
            {path: "QM317E_GUR", component: 완제품검사요청등록 },
            {path: "QM318E", component: 완제품검사내역등록 },
            {path: "QM320Q_GUR", component: 완제품검사수정 },
            {path: "QM417E", component: 완제품검사조회 },
            {path: "QM416E", component: 자재검사조회 },
            {path: "QM415E", component: 자재검사요청조회 },
            {path: "QM312E_IM", component: 클레임등록 },
            {path: "QM313E_IM", component: 클레임전송 },
            {path: "QM418E", component: 클레임내역조회 },

            
            
            //재고관리
            {path: "IV931Q", component: 현재고현황품목별},
            {path: "IV931Q1", component: 현재고현황창고별},
            {path: "IV932Q", component: 수불현황},
            {path: "IV901E", component: 기초재고등록},
            {path: "IV910E", component: 재고이동요청등록},
            {path: "IV910Q", component:재고이동요청현황 },
            {path: "IV911E", component:재고이동출고등록 },
            {path: "IV911Q", component:재고이동출고현황 },
            {path: "IV912E", component:재고이동입고등록 },
            {path: "IV912Q", component:재고이동입고현황 },
            {path: "IV921E_GUR", component:실사선별작업 },
            {path: "IV923E", component:실사등록 },
            {path: "IV925E", component:실사조정 },
            {path: "IV926E", component:월마감작업 },
            {path: "IV927", component: 재고평가},
            {path: "IV933Q", component:미사용재고조회 },
            {path: "IV933Q1", component:불용재고조회 },
            {path: "IV921E", component:실사선별현황 },
         
           
            
            
            
            //외주관리
            { path: "OS010E", component: 외주PL등록 },
            { path: "OS100Q", component: 외주PL정보조회 },
            { path: "OS420Q", component: 외주발주등록 }, //외주발주
            { path: "OS150E_TMP", component: 외주검사요청등록 },
            { path: "OS205E_TMP", component: 외주출고등록 },
            { path: "OS215E_TMP", component: 외주입고등록 },
            { path: "OS420Q_01", component: 외주실사등록 },
            { path: "OS435Q", component: 외주출고조회 },
            { path: "OS436Q", component: 외주입고조회 },
            { path: "OS432Q", component: 외주현재고현황 },
            { path: "OS433Q", component: 외주실사조회 },
            { path: "OS105E", component: 외주기초재고등록 },
            { path: "OS200", component: 외주실사선별작업 },
            { path: "OS434Q", component: 외주발주조회 },
            { path: "OS437Q", component: 외주미출고조회 },
            { path: "OS438Q", component: 외주미입고조회 },
            
            
            //수불관리
            {path:"YeongeobChulgo",component:영업출고현황},
            {path:"TR201E",component:구매입고등록},
            {path:"TR300E_GUR",component:자재출고등록},
            {path:"TR310E_GUR",component:영업입고등록},
            {path:"TR311E_GUR",component:영업출고등록},
            {path:"TR312E_GUR",component:영업출하등록},
            {path:"TR313E_GUR",component:영업반품등록},
            {path:"TR410Q_IM",component:자재입고조회},
            {path:"TR420Q_IM",component:자재출고조회},
            {path:"TR500E",component:영업입고조회},
            {path:"TR500E_GUR",component:영업출고조회},
            {path:"TR501E_GUR",component:영업반품조회},
            {path:"TR502E_GUR",component:거래명세서인쇄},

            //생산관리
            {path:"PP110E",component:공정등록},
            {path:"PP110E_1",component:공정수순등록},
            {path:"PP210E",component:생산계획등록},
          
            
            //모니터링
            {path:"MT001",component:infotab},
            {path:"MT002",component:거래처별입고현황},
            {path:"MT003",component:거래처별출고현황},
            
            
            //외주처관리
            {path:"AD1",component:월자재소요계획},
            {path:"AD10",component:일별검수실적},
            {path:"AD11",component:월별검수실적},
            {path:"AD2",component:주간자재소요계획},
            {path:"AD3",component:정상자재발주},
            {path:"AD4",component:추가자재발주},
            {path:"AD5",component:ASK발주},
            {path:"AD6",component:납품전표등록},
            {path:"AD7",component:일별납품실적},
            {path:"AD8",component:월별납품실적},
            {path:"AD9",component:매출자료출력},

            
        ]},

    ]
})
import store from './store';
import axios from 'axios'
var commonData = require('./commonData')
var uri = commonData.commonData.uri

router.beforeEach(async function(to, from, next) {
   
    
    //로그인창 없이 '/' 로 접근했을 때
    if (to.matched.some( function (routeInfo) {
        return routeInfo.meta.requiresAuth
      })) 
    {
     //로그인유저
        store.state.loginuser=sessionStorage.getItem("access")

    //시스템업무코드
    const res1=await axios.get(uri + "/select/BS000T")
    store.commit('set업무코드', res1.data);
      
    //품목데이터
    const res2=await axios.get(uri+"/select/BPR200T")
    store.commit('set품목코드', res2.data);
    

    //창고코드
    const res3=await axios.get(uri+"/select/BSA220T") 
    store.commit('set창고코드', res3.data);

     //프로그램
     const res4=await axios.get(uri+"/select/BS020T") 
        var list = res4.data.filter((x)=>{
            return x.PGM_CD.length == 2
        })
     store.commit('set프로그램', list);
    
     //사업장
     const res5=await axios.get(uri+"/select/BOR120T") 
     store.commit('set사업장', res5.data);

     //거래처
     const res6=await axios.get(uri+"/select/BCM100T") 
     store.commit('set거래처', res6.data);
    
     //작업장
     const res7=await axios.get(uri+"/select/BSA230T") 
     store.commit('set작업장', res7.data);

    //공정코드
     const res8=await axios.get(uri+"/select/PBS200T") 
     store.commit('set공정코드', res8.data);
    
     //부서
     const res9 = await axios.get(uri+'/select/department')
     store.commit('set부서그룹', JSON.parse(res9.data.result));   
     
     //사용자
     const res10 = await axios.get(uri+'/select/member')
     store.commit('set사용자', JSON.parse(res10.data.result));  
    
          
    //품목데이터BPR100T
    const res11=await axios.get(uri+"/select/BPR100T")
    store.commit('set품목코드100T', res11.data);
    
    //검사항목
    const res12 = await axios.get(uri+'/select/inspList')
     store.commit('set검사항목', JSON.parse(res12.data.result)); 

    
    if (sessionStorage.getItem('access') == null) {
        location.href='/login';
    }
        next()
    } 

    next()
    

    
    
})


export default router
