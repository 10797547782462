import store from './store';
import router from './router';
import XLSX from 'xlsx'

let functions= {
    data () {
        return {
            검색: {
            },

            //**************스크롤******************/
            HowShowNum: 20, //한번에 몇개씩 보여줄건지
            scrollnum: 0, //현재스크롤넘버
            scrolltarget: null, //스크롤기능타겟
            scrollY: 0,

            isLoading: true

    }
    },
    methods: {
        $bindValueToInput(num, str, opt, binding, pt) {

            if (num == 1) { //DB데이터 옵션에서 셀렉트 가능할때

                this
                    .DB데이터옵션
                    .filter(item => { //datalist에서 선택 후 글자를 지웠을때 오른쪽 인풋박스도 내용 지워지게
                        if (item[opt] !== str) {
                            this.검색[binding] = ""
                        }
                    })
                this
                    .DB데이터옵션
                    .filter(item => {
                        if (item[opt] == str) {
                            this.검색[binding] = item[binding]
                        }
                    })

            } else if (num == 2) { //store 에서 가져와야할떄
                store
                    .state[pt]
                    .filter(item => { //datalist에서 선택 후 글자를 지웠을때 오른쪽 인풋박스도 내용 지워지게
                        if (item[opt] !== str) {
                            this.no검색[binding] = ""
                        }
                    })
                store
                    .state[pt]
                    .filter(item => {
                        if (item[opt] == str) {
                            this.no검색[binding] = item[binding]
                        }
                    })
            } else if (num == 3) {
                this
                    .DB데이터옵션
                    .filter(item => { //datalist에서 선택 후 글자를 지웠을때 오른쪽 인풋박스도 내용 지워지게
                        if (item[opt] !== str) {
                            this.no검색[binding] = ""
                        }
                    })
                this
                    .DB데이터옵션
                    .filter(item => {
                        if (item[opt] == str) {

                            this.no검색[binding] = item[binding]
                        }
                    })

            }
        },
        $search() {

            //**************스크롤******************/
            this.HowShowNum= 20 //한번에 몇개씩 보여줄건지
            this.scrollnum=0 //현재스크롤넘버
            this.scrolltarget= null //스크롤기능타겟
            this.scrollY= 0

                            
                            
            var 검색key = Object.keys(this.검색);
            var a = this.검색;
            var arr = []; //검색할 컬럼 key담기
            var result = this.DB데이터옵션2;
            this.DB데이터 = []
            this.DB데이터옵션 = []
            
            //검색할 컬럼만 담기/ 값이 들어있는 컬럼만 뽑기
            for (var i = 0; i < 검색key.length; i++) {
                if (a[검색key[i]] !== "") {
                    arr.push(검색key[i])
                }
            }
            console.log('arr',arr);
            if (arr.length==0 &&this.date1==undefined&&this.date2==undefined) {
                this.DB데이터옵션 = result
                this.$scroll();
            } else {
                
                for (var e = 0; e < arr.length; e++) {
                    
                    result = result.filter((item) => {
                        if (item[arr[e]] != null) {
                            return item[arr[e]].includes(a[arr[e]])
                        }
                    })
                }
                console.log(result)
                if (this.date1 != undefined && this.date2 != undefined) {
                    if (this.date1 != "" && this.date2 != "") {
                        this.DB데이터옵션 = result.filter((e) => {
                            return e[this.datename] >= this.date1 && e[this.datename] <= this.date2
                        })
                    } else {
                        this.DB데이터옵션 = result
                    }
                }else {
                    this.DB데이터옵션 = result
                }
                console.log(this.DB데이터옵션)
                this.$scroll()
            }
            
        },
        $setscroll() {
            this.scrolltarget = document.querySelector('#div_scroll');
            this.scrolltarget.addEventListener('scroll', this.handleScroll);
    
        },
        $scroll(i=0,s="D") {

            //**************스크롤******************/
            if (i == 1) {
                if (s == "D") {
                    
                    this.scrolltarget = document.querySelector('#div_scroll');
                    this.scrolltarget.addEventListener('scroll', this.handleScroll);
                } else { //모달창스크롤
                    
                    this.scrolltarget = document.querySelector('#div_scrollm');
                    this.scrolltarget.addEventListener('scroll', this.handleScroll);
                }
    
                this.HowShowNum = 20 //한번에 몇개씩 보여줄건지
                this.scrollnum = 0 //현재스크롤넘버
            }

            var start = this.scrollnum * this.HowShowNum;
            var end = this.HowShowNum * (this.scrollnum + 1);
            
            // console.log('start',start);
            // console.log('end',end);

            if (end > this.DB데이터옵션.length) {
                end = this.DB데이터옵션.length
            }
            for (var t = start; t < end; t++) {
                this
                    .DB데이터
                    .push(this.DB데이터옵션[t])

            }
        },
       
        handleScroll(e, status = null) {
            // console.log('e',e);
            // console.log('clientHeight',e.target.clientHeight);
            // console.log('scrollTop',e.target.scrollTop);
            // console.log('offsetHeight',e.target.offsetHeight);
            // console.log('scrollHeight',e.target.scrollHeight);
            this.scrollY = e.target.scrollLeft;
            if (e.target.scrollLeft) {
                this
                    .scrolltarget
                    .removeEventListener('scroll', this.handleScroll);
            } else {

                var TotalScrollnum = Math.ceil(this.DB데이터옵션.length / this.HowShowNum)
                console.log('현재스크롤',this.scrollnum);
                console.log('전체스크롤수',TotalScrollnum);
                if (e.target.scrollTop + e.target.offsetHeight+20 >= e.target.scrollHeight) {
                    this.scrollnum += 1
                    console.log('스크롤수',this.scrollnum);
                    
                    if (TotalScrollnum < this.scrollnum) {
                        this
                            .scrolltarget
                            .removeEventListener('scroll', this.handleScroll);
                    } else {
                        this.$scroll()
                    }
                } else {
                    console.log('끝');
                }
            }
        },
        $코드명(str, sys_code = null) {
            const result = store.state.업무코드.filter(el => {
                return el.CODE_NM == str //***코드 변경 필수 => 셀렉트박스 구분에 맞게 변경 
            })
            var 구분된시스템코드=""
            sys_code!=null?구분된시스템코드=sys_code:
            구분된시스템코드 = result[0].MAIN_CD
            
            const result2 = store.state.업무코드.filter(el => {
                return el.MAIN_CD == 구분된시스템코드 && el.SUB_CODE!='*'
            })
            return result2
            
        },
        $품목코드(code, key) {
            
            for(var t=0; t<store.state.품목코드100.length; t++){
                if(store.state.품목코드100[t].ITEM_CODE==code){
                    return store.state.품목코드100[t][key]
                }
            }
        },

        $거래처코드(str) {
            for(var t=0; t<store.state.거래처.length; t++){
                if (store.state.거래처[t].CUSTOM_CODE == str) {

                    return store.state.거래처[t]["CUSTOM_NAME"]
                }
            }
        },
        $거래처코드r(str) {
            for (var t = 0; t < store.state.거래처.length; t++) {
                if (store.state.거래처[t].CUSTOM_NAME == str) {

                    return store
                        .state
                        .거래처[t]["CUSTOM_CODE"]
                }
            }
        },

        $공정코드(str) {
            for(var t=0; t<store.state.공정코드.length; t++){
                if (store.state.공정코드[t].PROG_WORK_CODE == str) {

                    return store.state.공정코드[t]["PROG_WORK_NAME"]
                }
            }
        },
        $창고코드(str) {
            for(var t=0; t<store.state.창고코드.length; t++){
                if (store.state.창고코드[t].TREE_CODE == str) {

                    return store.state.창고코드[t]["TREE_NAME"]
                }
            }
        },
        $창고코드r(str) {
            for(var t=0; t<store.state.창고코드.length; t++){
                if (store.state.창고코드[t].TREE_NAME == str) {

                    return store.state.창고코드[t]["TREE_CODE"]
                }
            }
        },
        $작업장(str) {
            for(var t=0; t<store.state.작업장.length; t++){
                if (store.state.작업장[t].TREE_CODE == str) {

                    return store.state.작업장[t]["TREE_NAME"]
                }
            }
        },

      
        $컬럼변환(컬럼변환, 업무코드, sys_code = null) {
            
            for(var yy=0; yy<this.$코드명(업무코드,sys_code).length; yy++){
                
                if (컬럼변환 == this.$코드명(업무코드,sys_code)[yy].SUB_CODE)
                {
                    return this.$코드명(업무코드,sys_code)[yy].CODE_NM
                }
            }
        },
        $컬럼변환code로(컬럼변환,업무코드, sys_code = null){
            for(var yy=0; yy<this.$코드명(업무코드,sys_code).length; yy++){
                
                if (컬럼변환 == this.$코드명(업무코드,sys_code)[yy].CODE_NM)
                {
                    return this.$코드명(업무코드,sys_code)[yy].SUB_CODE
                }
            }
        },
        $컬럼변환ByStore(컬럼변환,항목,필터,리턴){
            var result =  ""
            store.state[항목].filter(item => {
                  if(item[필터] == 컬럼변환){
                     result= item[리턴]
                  }
              })
             return result
        },
        
     
        async $log(action){
            var menuname=JSON.parse(sessionStorage.getItem('tabs'))
            var user_id=sessionStorage.getItem('access')
            var result=menuname.find((e)=>{
                return this.$route.fullPath.includes(e.url)==true
            })

            var json={};
            json.USER_ID=user_id;
            json.PAGE_URL=this.$route.fullPath;
            json.PAGE_LABEL=result.name;
            json.ACTION = action;
            
            await this.axios.post(this.$uri + "/insert/ACTIVITY_LOG", json)
            await this.select();
            console.log('json',json);
        },
        $sideopen(){
            var sidePart = document.querySelector('.sidePart');
            var dkbg = document.querySelector('.dkbg');
            sidePart.classList.add('on');
            dkbg.classList.add('on');
        },
        $sideclose(){
            var sidePart = document.querySelector('.sidePart');
            var dkbg = document.querySelector('.dkbg');
            sidePart.classList.remove('on');
            dkbg.classList.remove('on');
        },
        $type1(e){
            var pattern1 = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/; //한글
            var pattern2 = /[a-zA-Z]/; //영어
            var pattern3 = /[\{\}\[\]\/?,;:|\)*~`!^\-_+<>@\#$%&\\\=\(\'\"]/; //특수문자
            console.log(e.target.value)
            if(pattern1.test(e.target.value)||pattern3.test(e.target.value)){
                alert("숫자를 입력해주세요")
                e.target.value = null
            }else if(pattern2.test(e.target.value)){
                alert("숫자를 입력해주세요")
                e.target.value = null
            }
            console.log(e.target.value)

        },
        $콤마제거(e) {
            return e.replace(/,/gi, "")
        },

        async $print(num, url) {
            //QR에 발주번호 담기
            this.QRvalue=num
            //데이터 불러오기
            const res = await this.axios.get(this.$uri + '/select/balju?sort='+ num)
            this.프린트데이터 = JSON.parse(res.data.result)
            this.프린트데이터.세액 = this.프린트데이터.ORDER_P * 10%
            this.프린트데이터.forEach(item=>{
            item.세액 = item.ORDER_P * 10/100;
            this.단가 += item.ORDER_UNIT_P
            this.공급가액 += item.ORDER_P
            this.세액 += item.세액
            })
            //canvas 이미지로 변환
            var canvas = document.getElementsByTagName("canvas")[0]
            var image = new Image();
            image.src = canvas.toDataURL()
            document.getElementById('canvasWrap').innerHTML =  `<img src='${canvas.toDataURL()}' style='width:50px;position:relative;left:580px'>`
        
        
            window.onbeforeprint = function () { // 프린트 화면 호출 전 발생하는 이벤트
             document.body.innerHTML = document.getElementById('printArea').innerHTML; // 원하는 영역 지정
            }
            
            window.onafterprint =  function async () { // 프린트 출력 후 발생하는 이벤트
                location.reload()                
            }

            setTimeout( function () {
                window.print();
            }, 0);
            

        },
        
        $downExcel(pageName){
            
            const t = document.getElementById('table')
            const workBook = XLSX.utils.book_new() //book_new: workbook을 하나 만듭니다. 엑셀 파일을 하나 만드는 거죠
            //const workSheet = XLSX.utils.json_to_sheet(this.조회데이터) //json_to_sheet: json 데이터를 worksheet 형태로 변환합니다.
            const workSheet = XLSX.utils.table_to_sheet(t) //테이블 데이터를 worksheet 형태로 변환합니다.
            XLSX.utils.book_append_sheet(workBook, workSheet, 'Sheet')
            XLSX.writeFile(workBook, pageName + '.xlsx')
        },
        $중복제거(col) {
            var colarr=[]
            this.DB데이터옵션2.forEach((e) => {
                colarr.push(e[col])
            })
            colarr = colarr.reduce((ac, v) => ac.includes(v) ? ac : [...ac, v], []);

            return colarr
        },
        $num(num) {
            const ori_val = num;
            const changed_val = ori_val.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
            return changed_val
        },
        
    },

   
        
    computed: {
        selectAll_T:{
            get: function () {
                return false
            },
            set:function(value){
                this.chk_delete = []
                if(value){
                    this.DB데이터.forEach(item => this.chk_delete.push(item))
                }
            }
        },
    },
    mounted() {

    },
    
}

export default functions



