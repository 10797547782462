import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    loginuser:"",
    발주서: false,
    업무코드: "",
    품목코드: "",
    품목코드100: "",
    거래처:"",
    창고코드:"",
    프로그램:"",
    사업장:"",
    작업장:"",
    공정코드: "",
    TREE_CODE:[{code:"3",name:"완제품"},{code:"4",name:"자재"},{code:"5",name:"외주"}],
    부서그룹:"",
    사용자:"",
    검사항목:"",

  },
  mutations: {
    open발주서 (state) {
      state.발주서 = true
    },
    set업무코드 :(state,payload) =>{
      state.업무코드 = payload;
    },
    set품목코드 :(state,payload) =>{
      state.품목코드 = payload

    },
    set품목코드100T :(state,payload) =>{
      state.품목코드100 = payload

    },
    set거래처 :(state,payload) =>{
      //console.log(payload)
      
      state.거래처 = payload
    },
    set창고코드 :(state,payload) =>{
      //console.log(payload)
      state.창고코드 = payload
    },
    set프로그램 :(state,payload) =>{
      state.프로그램 = payload
    },
    set사업장 :(state,payload) =>{
      //console.log(payload)
      state.사업장 = payload
    },
    set작업장 :(state,payload) =>{
    //console.log(payload)

      state.작업장 = payload
    },
    set공정코드 :(state,payload) =>{
      //console.log(payload)
      state.공정코드 = payload
    },
    set부서그룹 :(state,payload) =>{
      state.부서그룹 = payload
    },
    set사용자 :(state,payload) =>{
      state.사용자 = payload
    },
    set검사항목 :(state,payload) =>{
      state.검사항목 = payload
    },
  }
})
export default store